<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>

    <navbar v-if="showNavbar" :docsName="docsName" />

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>

              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid || !portalinfoValid"
                    @click="speichern(invalid)"
                    @shortkey="speichern(invalid)"
                    v-tooltip
                    :title="
                      invalid
                        ? $t('global.notallfieldsfilledcorrectly')
                        : !portalinfoValid
                        ? $t('global.portalinfonotvalid')
                        : ''
                    "
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                      $t("global.tolist")
                    }}
                  </button>
                  <button
                    v-if="editable"
                    class="btn btn-primary mr-2"
                    @click="erstelleKurzveranstaltung"
                    :disabled="
                      invalid || !portalinfoValid || hasChanged || !lehrgang.id
                    "
                    :title="
                      hasChanged || !lehrgang.id
                        ? $t('global.shortevent') + ' ' + $t('global.savefirst')
                        : invalid
                        ? $t('global.notallfieldsfilledcorrectly')
                        : !portalinfoValid
                        ? $t('global.portalinfonotvalid')
                        : ''
                    "
                  >
                    <font-awesome-icon
                      icon="fa-duotone fa-gem"
                      class="mr-2"
                    />{{ $t("global.createshortevent") }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="berechtigungen.m_kurzveranstaltung.delete"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <kurzveranstaltung-stammdaten
            :lehrgang="lehrgang"
            :editable="editable"
            :isTemplate="true"
          />
        </div>

        <div v-if="lehrgangid" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav-item" v-if="lehrgang.portalanzeige">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    :to="{
                      name: 'template-kurzveranstaltung',
                      params: { lehrgangid: lehrgangid, anzeige: 0 },
                    }"
                    >{{ $t("global.portalinformation") }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <!-- Start Portalinfos -->
                <div v-show="anzeige === 0 && lehrgang.portalanzeige">
                  <portalinformationen
                    :lehrgang="lehrgang"
                    :portalinfoId="portalinfoId"
                    :shown="anzeige == 0 ? true : false"
                    :editable="editable"
                    @portalinfoChanged="portalinfoChanged"
                    @portalinfoValidChanged="portalinfoValidChanged"
                  />
                </div>
                <!-- Ende Portalinfos -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="bildungsgang-loeschen-modal"
      @confirmed="kurzveranstaltungLoeschen"
    />

    <change-watcher-alert />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import AppApi from "@/AppApi";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";

import KurzveranstaltungStammdaten from "@/components/Bildung/Kurzveranstaltung/Stammdaten";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import Portalinformationen from "@/components/Reiter/Portalinformationen";

export default {
  name: "template-kurzveranstaltung",
  components: {
    HeadMenu,
    Navbar,
    KurzveranstaltungStammdaten,
    LoeschenModal,
    Portalinformationen,
  },

  mixins: [page, ChangeWatcher],
  props: {
    lehrgangid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fromRoute: null,
      lehrgang: {
        veranstaltungsstatus: null,
        leitung: { personName: this.$t("global.notlinked") },
        kontakt: { personName: this.$t("global.notlinked") },
        verantwortlich: { personName: this.$t("global.notlinked") },
        portalinfo: { id: null },
        portalanzeige: true,
        sprachen: [],
        vorbereitung: "",
      },
      portalinfoValid: true,
    };
  },
  computed: {
    navbarTitel() {
      if (this.lehrgang.id)
        return (
          this.$t("global.templateshortevent") +
          " #" +
          this.lehrgang.veranstaltungsnummer +
          (this.lehrgang.bezeichnung ? " - " + this.lehrgang.bezeichnung : "")
        );
      else return this.$t("global.templateshortevent");
    },
    sprachen: {
      get() {
        return this.$store.state.veranstaltung.sprachen;
      },
    },
    portalinfoId() {
      if (this.lehrgang.portalinfo?.id) return this.lehrgang.portalinfo?.id;
      return null;
    },
  },
  watch: {
    sprachen: {
      deep: true,
      handler(val) {
        if (val) {
          if (this.lehrgang.sprachen.length === 0) this.setLanguage();
        }
      },
    },
  },
  created() {
    this.fromRoute = this.$route.params.fromRoute;
    if (this.lehrgangid) {
      this.loading = true;

      AppApi.get("template/kurzveranstaltung/", {
        params: { id: this.lehrgangid },
      })
        .then((response) => {
          this.initializeKurzveranstaltung(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.editable = true;
      this.setLanguage();
    }
  },
  mounted: function () {},
  methods: {
    erstelleKurzveranstaltung() {
      if (!this.lehrgangid) {
        this.$notify({
          type: "warning",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noeducationcourseassigned"),
        });
      } else {
        this.isBusy = true;
        var json = Object.assign({}, this.lehrgang);
        AppApi.post("template/vontemplate/?id=" + this.lehrgangid, json)
          .then(() => {
            this.$router.push({ name: "Kurzveranstaltungsliste" });
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },

    portalinfoChanged() {
      this.hasChanged = true;
    },

    setLanguage() {
      const defaultSprache = this.$CONST("CONFIG").DEFAULTLANGUAGE;

      let defaultSprachenObj = this.sprachen.find(
        (sprache) => sprache.id === defaultSprache
      );
      //this.$set(this.lehrgang, "sprache", defaultSprachenObj);
      this.lehrgang.sprachen.push(defaultSprachenObj);
    },

    abbrechen() {
      this.$router.push({
        name: this.fromRoute || "template-kurzveranstaltungs-liste",
      });
    },

    initializeKurzveranstaltung(lehrgang) {
      if (lehrgang) {
        this.lehrgang = lehrgang;

        if (
          this.lehrgang.verantwortlich &&
          this.lehrgang.verantwortlich.length === 0
        ) {
          this.lehrgang.verantwortlich.push({
            personName: this.$t("global.notlinked"),
          });
        }

        if (!this.lehrgang.leitung)
          this.lehrgang.leitung = {
            personName: this.$t("global.notlinked"),
          };

        if (!this.lehrgang.veranstaltungsstatus)
          this.lehrgang.veranstaltungsstatus = {
            personName: this.$t("global.notlinked"),
          };

        if (this.lehrgang.sprachen.length === 0) {
          this.setLanguage();
        }

        this.editable =
          this.lehrgang.veranstaltungsstatus.id ==
          this.$CONST("LEHRGANGSTATI").PLANNED
            ? true
            : true;

        // TODO: diesen Block entfernen, sobald Backend diesen Flag mitsendet
        if (lehrgang.portalanzeige) {
          this.lehrgang.portalanzeige = lehrgang.portalanzeige;
        } else {
          this.lehrgang.portalanzeige = true;
        }
      } else this.editable = true;

      this.editable = this.berechtigungen.m_kurzveranstaltung.update;

      this.initChangeWatcher(this.lehrgang);
    },

    speichern(invalid) {
      if (this.loading) return;

      if (invalid) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.notallfieldsfilledcorrectly"),
        });
        return;
      }

      this.loading = true;
      if (this.lehrgang.teilnehmer_max === "") {
        this.lehrgang.teilnehmer_max = null;
      }

      var json = Object.assign({}, this.lehrgang);

      // Wenn Postalinfos vorhanden, diese mitspeichern
      if (this.lehrgang.portalanzeige && this.portalinfoValid) {
        this.$bus.$emit("savePortalinfo");
      } else if (this.lehrgang.portalanzeige && !this.portalinfoValid) {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.portalinfonotvalid"),
        });
      }

      // Relationen auf ID reduzieren

      json.veranstaltungsstatus = this.lehrgang.veranstaltungsstatus
        ? this.lehrgang.veranstaltungsstatus.id
        : this.$CONST("LEHRGANGSTATI").PLANNED;

      json.leitung = this.lehrgang.leitung.id;

      // Defaultwerte

      json.veranstaltungstyp = this.$CONST("VERANSTALTUNGSTYPEN").STUDIES;

      if (!this.lehrgangid) {
        AppApi.post("template/kurzveranstaltung/", json)
          .then((response) => {
            this.initializeKurzveranstaltung(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text:
                this.$t("global.templateshortevent") +
                " " +
                this.$t("global.successfullysaved"),
            });

            this.$router.replace({
              name: "template-kurzveranstaltung",
              params: { lehrgangid: response.data.id, anzeige: 0 },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        AppApi.put("template/kurzveranstaltung/", json, {
          params: { id: this.lehrgang.id },
        })
          .then((response) => {
            this.initializeKurzveranstaltung(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text:
                this.$t("global.templateshortevent") +
                " " +
                this.$t("global.successfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "bildungsgang-loeschen-modal");
    },

    kurzveranstaltungLoeschen() {
      let json = [this.lehrgangid];

      this.disableChangeWatcher();
      AppApi.delete("template/kurzveranstaltung/", { data: json }).then(() => {
        this.$router.push({ name: "template-kurzveranstaltungs-liste" });
      });
    },

    portalinfoValidChanged(newVal) {
      this.portalinfoValid = newVal;
    },
  },
};
</script>

<style></style>
