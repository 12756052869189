//import "babel-polyfill";
//import "jquery";
import "bootstrap/dist/js/bootstrap";

import "./extensions/DateExtension";
import "./extensions/ArrayExtension";
import "./extensions/StringExtension";

import "vue2-dropzone/dist/vue2Dropzone.min.css";

import numeral from "numeral";

import Vue from "vue";

import Messagebox from "./components/Modals/Messagebox";

import CKEditor from "@ckeditor/ckeditor5-vue2";
Vue.use(CKEditor);

import MonthPicker from "vue-month-picker";
import MonthPickerInput from "vue-month-picker";

Vue.use(MonthPicker);
Vue.use(MonthPickerInput);

import { BBadge } from "bootstrap-vue";
Vue.component("b-badge", BBadge);

/*** FORM VALIDATION */

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, regex, double } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("double", double);
extend("regex", regex);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

import Permissions from "./plugins/Berechtigungen";
import EnvironmentPlugin from "./plugins/environment";
import { isMobile, isAndroid, isWinPhone, isIOS } from "mobile-device-detect";

import Chartkick from "vue-chartkick";
import Chart from "chart.js";

Vue.use(Chartkick.use(Chart));

import VueRouter from "vue-router";
Vue.use(VueRouter);

import router from "./Router";
import store from "./store";

import App from "./App";

/* FONT AWESOME 6.0 */

/* import font awesome icon component */
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faTachometerAlt,
  faChartGantt,
  faAngleLeft,
  faAngleRight,
  faSync,
  faMinus,
  faPlus,
  faArrowAltFromTop,
  faFileExport,
  faXmark,
  faRectangleHistory,
  faMoneyCheckAlt,
  faSearch,
  faAngleDown,
  faAngleUp,
  faCog,
  faEnvelope,
  faThumbtack,
  faPencil,
  faHouse,
  faWarning,
  faFileAlt,
  faSackDollar,
  faArrowAltFromLeft,
  faListCheck,
  faComments,
  faText,
  faBullseyeArrow,
  faArrowUpRight,
  faBuildingColumns,
  faEye,
  faEyeSlash,
  faShieldCheck,
  faHighlighter,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faBuilding,
  faBuildingUser,
  faUserFriends,
  faMapMarkerAlt,
  faMoneyBill,
  faShieldExclamation,
  faSquareArrowDown,
  faWallet,
  faReceipt,
  faShoppingBasket,
  faCalendarDay,
  faCalendarAlt,
  faTvAlt,
  faPrint,
  faEnvelopeOpenText,
  faMemo,
  faFileUpload,
  faCommentsAlt,
  faSignInAlt,
  faCalendarCheck,
  faGraduationCap,
  faUsersClass,
  faBooks,
  faAward,
  faStar,
  faUserClock,
  faAddressBook,
  faHome,
  faSlidersVSquare,
  faDoorOpen,
  faTicket,
  faTrash,
  faBook,
  faFileInvoiceDollar,
  faCalendarLinesPen,
  faCopy,
  faArrowCircleRight,
  faPencilAlt,
  faExclamationTriangle,
  faBracketSquare,
  faSchool,
  faChalkboard,
  faShoppingBasketAlt,
  faGem,
  faDesktop,
  faKey,
  faUser,
  faDollarSign,
  faCheckSquare,
  faExternalLinkSquareAlt,
  faTypewriter,
  faFileImport,
  faChevronCircleLeft,
  faMagnifyingGlass,
  faSpinner,
  faLayerPlus,
  faBallotCheck,
  faCoins,
  faGlobe,
  faFileZipper,
  faFileSpreadsheet,
  faFileCertificate,
  faBookArrowUp,
  faDiploma,
  faArrowRightToBracket,
  faPhoneArrowUpRight,
  faUserGroup,
  faUsersRays,
  faLink,
  faArrowsMinimize,
  faCircleX,
  faQuestionCircle,
  faUserCheck as fadUserCheck,
  faUserTimes,
  faSquarePollHorizontal,
  faClipboardQuestion,
  faTreePalm,
  faHouseLaptop,
  faFaceMask,
  faPersonRifle,
  faHandHoldingHand,
  faArrowTurnDownRight,
} from "@fortawesome/pro-duotone-svg-icons";

import {
  faPeopleArrows,
  faUsersCrown,
  faCircle,
  faAlarmClock,
  faUserGraduate,
  faUsers,
  faList,
  faSignOutAlt,
  faCircleCheck,
  faBan,
  faTrashAlt,
  faPuzzlePiece,
  faUndo,
  faArrowLeft,
  faCircleArrowLeft,
  faCircleArrowRight,
  faPlusCircle,
  faCalendar,
  faCircleXmark,
  faCheck,
  faLock,
  faUnlock,
  faUserCheck,
  faStarHalfStroke,
  faChevronDown,
  faChevronUp,
  faArrowDownToBracket,
  faClock,
  faUpRightFromSquare,
  faClipboardList,
  faInfo,
  faCircleInfo,
  faSort,
  faBars,
  faCheckSquare as fasCheckSquare,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faTimes,
  faCheckSquare as falCheckSquare,
  faList as falList,
} from "@fortawesome/pro-light-svg-icons";

import {
  faPencil as fatPencil,
  faList as fatList,
} from "@fortawesome/pro-thin-svg-icons";

library.add(
  falList,
  falCheckSquare,
  fasCheckSquare,
  faInfo,
  faUserTimes,
  fadUserCheck,
  faCircleX,
  faArrowsMinimize,
  faLink,
  faUsersRays,
  faUpRightFromSquare,
  faUserGroup,
  faPhoneArrowUpRight,
  faBuildingColumns,
  faClock,
  faArrowDownToBracket,
  faArrowRightToBracket,
  faChevronUp,
  faChevronDown,
  faDiploma,
  faBookArrowUp,
  faFileCertificate,
  faFileSpreadsheet,
  faCircleInfo,
  faFileZipper,
  faArrowUpRight,
  faBullseyeArrow,
  faGlobe,
  faUnlock,
  faLock,
  faBallotCheck,
  faCircleXmark,
  faFileImport,
  faTypewriter,
  faKey,
  faCheck,
  faGem,
  faCalendar,
  faLayerPlus,
  faShoppingBasketAlt,
  faText,
  faChalkboard,
  faSchool,
  faListCheck,
  faBracketSquare,
  faArrowAltFromLeft,
  faSackDollar,
  faFileAlt,
  faWarning,
  faHouse,
  faThumbtack,
  faCog,
  faArrowLeft,
  faArrowCircleRight,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCopy,
  faAngleUp,
  faAngleDown,
  faMinus,
  faPencil,
  faPencilAlt,
  faTimes,
  faSearch,
  faPuzzlePiece,
  faCalendarLinesPen,
  faMoneyCheckAlt,
  faRectangleHistory,
  faFileInvoiceDollar,
  faXmark,
  faFileExport,
  faArrowAltFromTop,
  faSquareArrowDown,
  faTrashAlt,
  faBan,
  faCircleCheck,
  faTrash,
  faMemo,
  faList,
  faTicket,
  faPlus,
  faPlusCircle,
  faUser,
  faUsers,
  faUndo,
  faDollarSign,
  faSpinner,
  faDoorOpen,
  faDesktop,
  faSlidersVSquare,
  faSync,
  faAngleLeft,
  faAngleRight,
  faHome,
  faAddressBook,
  faUserClock,
  faStar,
  faAward,
  faBook,
  faBooks,
  faUsersClass,
  faGraduationCap,
  faUserGraduate,
  faCircle,
  faPeopleArrows,
  faAlarmClock,
  faBuilding,
  faBuildingUser,
  faUserFriends,
  faUsersCrown,
  faMapMarkerAlt,
  faTachometerAlt,
  faMoneyBill,
  faShieldExclamation,
  faExclamationTriangle,
  faWallet,
  faReceipt,
  faShoppingBasket,
  faCalendarDay,
  faCalendarAlt,
  faTvAlt,
  faPrint,
  faEnvelope,
  faEnvelopeOpenText,
  faFileUpload,
  faCommentsAlt,
  faComments,
  faSignInAlt,
  faSignOutAlt,
  faCalendarCheck,
  faChartGantt,
  faCheckSquare,
  faExternalLinkSquareAlt,
  faChevronCircleLeft,
  faMagnifyingGlass,
  faUserCheck,
  faCoins,
  faStarHalfStroke,
  faEye,
  faEyeSlash,
  faShieldCheck,
  faQuestionCircle,
  fatPencil,
  faHandHoldingHand,
  faPersonRifle,
  faFaceMask,
  faHouseLaptop,
  faTreePalm,
  faArrowTurnDownRight,
  faClipboardList,
  faSquarePollHorizontal,
  faClipboardQuestion,
  faHighlighter,
  faSort,
  faBars
);

import {
  TablePlugin,
  FormCheckboxPlugin,
  FormPlugin,
  FormInputPlugin,
  DropdownPlugin,
  CollapsePlugin,
  CardPlugin,
  ButtonPlugin,
} from "bootstrap-vue";

Vue.use(TablePlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormPlugin);
Vue.use(FormInputPlugin);
Vue.use(DropdownPlugin);
Vue.use(CollapsePlugin);
Vue.use(CardPlugin);
Vue.use(ButtonPlugin);

import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

Vue.use(VueTelInput, { autoDefaultCountry: false, defaultCountry: "CH" });

import vueNumeralFilterInstaller from "vue-numeral-filter";
Vue.use(vueNumeralFilterInstaller, {
  locale: "de-ch",
});

import ForbiddenContent from "@/components/global/ForbiddenContent";

Vue.component("forbidden-content", ForbiddenContent);
Vue.component("messagebox", Messagebox);

import VTooltip from "v-tooltip";
Vue.use(VTooltip);

import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

import "./utils/VueFilter";

numeral.locale("de-ch");
numeral.localeData("de-ch").delimiters.thousands = "'";
numeral.localeData("de-ch").delimiters.decimal = ".";

import VueShortKey from "vue-shortkey";
Vue.use(VueShortKey);

//Vue-Notification mit Custom Settings:
import "./utils/Notifications";

Vue.use(Permissions);

Vue.use(EnvironmentPlugin, {
  isMobile: isMobile || isAndroid || isWinPhone || isIOS,
});

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import datepicker from "./components/Datepicker";
Vue.component("datepicker", datepicker);

import PdfLayer from "./components/global/PdfLayer";
Vue.component("pdf-layer", PdfLayer);

Vue.config.productionTip = false;

if (JSON.parse(process.env.VUE_APP_DEV_TOOLS)) Vue.config.devtools = true;

// bus aktivieren
Vue.prototype.$bus = new Vue();

import AppKeys from "@/AppKeys.js";
Vue.use(AppKeys);

// Mehrsprachigkeit
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  de: {},
  en: {},
  fr: {},
};

const i18n = new VueI18n({
  locale: "de",
  messages: messages,
  silentTranslationWarn: true,
});

export function loadLanguage(lang, texts) {
  i18n.setLocaleMessage(lang, texts);
}

// App initialisieren

new Vue({
  render: (h) => h(App),
  store,
  router,
  i18n,
}).$mount("#bxch-app");
