var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'mt-30-px': !_vm.showNavbar }},[_c('notifications'),(_vm.showNavbar)?_c('navbar',{attrs:{"docsName":_vm.docsName}}):_vm._e(),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"container-fluid row"},[(_vm.showNavbar)?_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 block br-t-l-0 mb-3"},[_c('head-menu',{staticClass:"d-xl-flex",attrs:{"col":12,"headline":_vm.navbarTitel}}),_c('div',{staticClass:"row mt-2 d-flex justify-content-between"},[_c('div',{staticClass:"ml-4"},[(_vm.editable)?_c('button',{directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['ctrl', 's']),expression:"['ctrl', 's']",modifiers:{"once":true}},{name:"tooltip",rawName:"v-tooltip"}],staticClass:"btn btn-success mr-2",attrs:{"disabled":invalid || !_vm.portalinfoValid,"title":invalid
                      ? _vm.$t('global.notallfieldsfilledcorrectly')
                      : !_vm.portalinfoValid
                      ? _vm.$t('global.portalinfonotvalid')
                      : ''},on:{"click":function($event){return _vm.speichern(invalid)},"shortkey":function($event){return _vm.speichern(invalid)}}},[_vm._v(" "+_vm._s(_vm.$t("global.save"))+" ")]):_vm._e(),_c('button',{staticClass:"btn btn-primary mr-2",on:{"click":_vm.abbrechen}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-solid fa-list"}}),_vm._v(_vm._s(_vm.$t("global.tolist"))+" ")],1),(_vm.editable)?_c('button',{staticClass:"btn btn-primary mr-2",attrs:{"disabled":invalid || !_vm.portalinfoValid || _vm.hasChanged || !_vm.lehrgang.id,"title":_vm.hasChanged || !_vm.lehrgang.id
                      ? _vm.$t('global.shortevent') + ' ' + _vm.$t('global.savefirst')
                      : invalid
                      ? _vm.$t('global.notallfieldsfilledcorrectly')
                      : !_vm.portalinfoValid
                      ? _vm.$t('global.portalinfonotvalid')
                      : ''},on:{"click":_vm.erstelleKurzveranstaltung}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-duotone fa-gem"}}),_vm._v(_vm._s(_vm.$t("global.createshortevent"))+" ")],1):_vm._e()]),_c('div',{staticClass:"mr-4"},[(_vm.berechtigungen.m_kurzveranstaltung.delete)?_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.oeffneLoeschenModal}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"fa-duotone fa-trash"}}),_c('span',[_vm._v(_vm._s(_vm.$t("global.delete")))])],1):_vm._e()])])],1)])]):_vm._e(),_c('div',{staticClass:"col-xl-12 pr-4",class:{ 'col-xl-12': !_vm.showNavbar }},[_c('kurzveranstaltung-stammdaten',{attrs:{"lehrgang":_vm.lehrgang,"editable":_vm.editable,"isTemplate":true}})],1),(_vm.lehrgangid)?_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12 p-0"},[_c('ul',{staticClass:"nav nav-tabs mt-3 fs-28"},[(_vm.lehrgang.portalanzeige)?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"primary-headline-text nav-link",class:{ active: _vm.anzeige === 0 },attrs:{"to":{
                    name: 'template-kurzveranstaltung',
                    params: { lehrgangid: _vm.lehrgangid, anzeige: 0 },
                  }}},[_vm._v(_vm._s(_vm.$t("global.portalinformation")))])],1):_vm._e()])]),_c('div',{staticClass:"col-xl-12 p-0"},[_c('div',{staticClass:"tab-container p-3"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.anzeige === 0 && _vm.lehrgang.portalanzeige),expression:"anzeige === 0 && lehrgang.portalanzeige"}]},[_c('portalinformationen',{attrs:{"lehrgang":_vm.lehrgang,"portalinfoId":_vm.portalinfoId,"shown":_vm.anzeige == 0 ? true : false,"editable":_vm.editable},on:{"portalinfoChanged":_vm.portalinfoChanged,"portalinfoValidChanged":_vm.portalinfoValidChanged}})],1)])])])]):_vm._e()])]}}])}),_c('loading-overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]}),_c('loeschen-modal',{attrs:{"id":"bildungsgang-loeschen-modal"},on:{"confirmed":_vm.kurzveranstaltungLoeschen}}),_c('change-watcher-alert')],1)}
var staticRenderFns = []

export { render, staticRenderFns }