import Bildungsgangliste from "@/pages/Dashboardlisten/Bildungsgangliste";
import Notenuebersicht from "@/pages/Bildung/Notenuebersicht";
import Endnotenuebersicht from "@/pages/Bildung/Endnotenuebersicht";
import LehrgangsuebergreifendeNotenuebersicht from "@/pages/Bildung/LehrgangsuebergreifendeNotenuebersicht";
import Anwesenheitsuebersicht from "@/pages/Bildung/Anwesenheitsuebersicht";
import Bildungsgang from "@/pages/Bildung/Bildungsgang";
import Klassenliste from "@/pages/Dashboardlisten/Klassenliste";
import Klasse from "@/pages/Bildung/Klasse";
import Faecherliste from "@/pages/Dashboardlisten/Faecherliste";
import Bildungsliste from "@/pages/Dashboardlisten/Bildungsliste";
import Kursliste from "@/pages/Dashboardlisten/Kursliste";
import Fach from "@/pages/Bildung/Fach";
import Kurs from "@/pages/Bildung/Kurs";
import Event from "@/pages/Bildung/Event";
import Infoanlass from "@/pages/Bildung/InfoAnlass";
import Anwesenheit from "@/pages/Bildung/Anwesenheit";
import Anwesenheitsliste from "@/pages/Dashboardlisten/Anwesenheitsliste";
import Kurzveranstaltung from "@/pages/Bildung/Kurzveranstaltung";
import Kurzveranstaltungsliste from "@/pages/Dashboardlisten/Kurzveranstaltungsliste";

import Bildungskategorien from "@/mock/Bildungskategorien";

export default [
  {
    path: "/Bildung/Bildungsgangliste",
    name: "bildungsgang-liste",
    component: Bildungsgangliste,
  },
  {
    path: "/Bildung/Bildungsgang/:lehrgangid?/:anzeige",
    name: "bildungsgang",
    component: Bildungsgang,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        lehrgangid: route.params.lehrgangid,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },

  {
    path: "/Bildung/Notenuebersicht/:klassenid?/:anzeige",
    name: "Notenuebersicht",
    component: Notenuebersicht,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        klassenid: route.params.klassenid,
        anzeige: isNaN(a) ? 0 : a,
        //notenuebersichtid: route.params.notenuebersichtid,
      };
    },
  },

  {
    path: "/Bildung/Endnotenuebersicht/:lehrgangid?/:anzeige",
    name: "Endnotenuebersicht",
    component: Endnotenuebersicht,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        lehrgangid: route.params.lehrgangid,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },

  {
    path: "/Bildung/LehrgangsuebergreifendeNotenuebersicht/:lehrgangid?/",
    name: "LehrgangsuebergreifendeNotenuebersicht",
    component: LehrgangsuebergreifendeNotenuebersicht,
    props: (route) => {
      return {
        lehrgangid: route.params.lehrgangid,
      };
    },
  },

  {
    path: "/Bildung/Anwesenheitsuebersicht/:lehrgangid?/:bildungsgangtyp?",
    name: "Anwesenheitsuebersicht",
    component: Anwesenheitsuebersicht,
    props: (route) => {
      return {
        lehrgangid: route.params.lehrgangid,
        bildungsgangtyp: route.params.bildungsgangtyp,
      };
    },
  },

  {
    path: "/Bildung/Klassenliste",
    name: "klassen-liste",
    component: Klassenliste,
  },
  {
    path: "/Bildung/Klasse/:klassenid?/:anzeige",
    name: "klasse",
    component: Klasse,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        klassenid: route.params.klassenid,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/Bildung/Faecherliste",
    name: "faecher-liste",
    component: Faecherliste,
  },
  {
    path: "/Bildung/Fach/:fachid?/:anzeige",
    name: "fach",
    component: Fach,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        fachid: route.params.fachid,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/bildung/kurse",
    name: "kurs-liste",
    component: Kursliste,
    props: { kategorieProp: Bildungskategorien.kurse },
  },
  {
    path: "/Bildung/Kurs/:kursid?/:anzeige",
    name: "kurs",
    component: Kurs,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        kursid: route.params.kursid,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/bildung/infoanlass",
    name: "infoanlass-liste",
    component: Bildungsliste,
    props: { kategorieProp: Bildungskategorien.infoanlass },
  },
  {
    path: "/Bildung/InfoAnlass/:infoanlassid?/:anzeige",
    name: "infoanlass",
    component: Infoanlass,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        infoanlassid: route.params.infoanlassid,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/bildung/events",
    name: "event-liste",
    component: Bildungsliste,
    props: { kategorieProp: Bildungskategorien.event },
  },
  {
    path: "/Bildung/Events/:eventid?/:anzeige",
    name: "event",
    component: Event,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        eventid: route.params.eventid,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
  {
    path: "/bildung/anwesenheit/:vid/:tid",
    name: "anwesenheit",
    props: true,
    component: Anwesenheit,
  },

  {
    path: "/bildung/anwesenheitsliste",
    name: "Anwesenheitsliste",
    component: Anwesenheitsliste,
  },
  {
    path: "/bildung/bildungsliste",
    name: "Bildungsliste",
    component: Bildungsliste,
  },

  {
    path: "/bildung/hf",
    name: "HF",
    component: Bildungsliste,
    props: { kategorieProp: Bildungskategorien.hf },
  },
  {
    path: "/bildung/hfp",
    name: "HFP",
    component: Bildungsliste,
    props: { kategorieProp: Bildungskategorien.hfp },
  },
  {
    path: "/bildung/nds",
    name: "NDS",
    component: Bildungsliste,
    props: { kategorieProp: Bildungskategorien.nds },
  },

  {
    path: "/bildung/faecher",
    name: "Faecher",
    component: Bildungsliste,
    props: { kategorieProp: Bildungskategorien.faecher },
  },
  {
    path: "/bildung/semester",
    name: "Klassen",
    component: Bildungsliste,
    props: { kategorieProp: Bildungskategorien.klasse },
  },
  {
    path: "/bildung/bildungsgang",
    name: "Bildungsgang",
    component: Bildungsliste,
    props: { kategorieProp: Bildungskategorien.bildungsgang },
  },

  /*** Muss umgearbeitet werden ***/

  {
    path: "/bildung/bildungsgangliste",
    name: "Bildungsgangliste",
    component: () => import("@/pages/Dashboardlisten/Bildungsgangliste"),
  },

  {
    path: "/bildung/ausbildungsuebersichtsliste",
    name: "AusbildungsUebersichtsListe",
    component: () => import("@/pages/Dashboardlisten/AusbildungsUebersichtsListe"),
  },

  {
    path: "/Bildung/kurzveranstaltungsliste",
    name: "Kurzveranstaltungsliste",
    component: Kurzveranstaltungsliste,
  },

  {
    path: "/Bildung/kurzveranstaltung/:lehrgangid?/:anzeige",
    name: "Kurzveranstaltung",
    component: Kurzveranstaltung,
    props: (route) => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        lehrgangid: route.params.lehrgangid,
        anzeige: isNaN(a) ? 0 : a,
      };
    },
  },
];
