<template>
  <div>
    <container-headline
      :headline="$t('global.communicationadress')"
      :col="6"
    ></container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-6">
              <single-select-firma
                id="rechnungviaFirma"
                :firma="firma"
                filterAdr="174D3E52C06"
                :editable="editable"
                :allowOpen="true"
                :label="$t('global.company')"
                @confirmed="setFirma"
                @input="onCheckboxChange"
              />
            </div>
            <div class="col-xl-6">
              <div
                class="form-check"
                v-tooltip.hover
                :title="$t('global.communicationadresstooltip')"
              >
                <input
                  type="checkbox"
                  v-model="mitPersonMail"
                  id="mitPersonMailCheck"
                  class="form-check-input"
                  @input="onCheckboxChange"
                />
                <label>{{ $t("global.person_email") }}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-select
                  v-model="kommunikationsadresse"
                  @input="onSelect(kommunikationsadresse)"
                  label="adresseKomplett"
                  :options="adressen"
                  :placeholder="$t('global.communicationadress')"
                  :tabindex="4"
                >
                  <span slot="no-options">{{ $t("global.taptosearch") }}</span>
                </v-select>
                <label>{{ $t("global.postaladress") }}</label>
              </div>
            </div>
            <div class="col-xl-6" v-if="firma.mailadressen">
              <div class="form-group">
                <input
                  type="text"
                  v-model="kommunikationsmail.bezeichnung"
                  label="bezeichnung"
                  readonly
                  class="form-control"
                />
                <label>{{ $t("global.email") }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";

export default {
  name: "Geschaeftspartner-Kommunikationsadresse",
  components: {
    ContainerHeadline,
    SingleSelectFirma,
  },
  mixins: [],
  props: {
    person: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    roles: {
      type: Object,
      default: function () {},
    },
  },
  data() {
    return {
      firma: {},
      mitPersonMail: true,
      kommunikationsadresse: null,
      adressen: [],
      kommunikationsmail: null,
    };
  },
  computed: {},
  watch: {
    person: {
      deep: true,
      handler: function () {
        this.firma = this.person.viafirma ? this.person.viafirma : {};
        this.adressen = this.person.viafirma
          ? this.person.viafirma.adressen
          : {};
        this.mitPersonMail = this.person.mitpersonmail
          ? this.person.mitpersonmail
          : false;
        this.kommunikationsadresse = this.person.kommunikationsadresse
          ? this.person.kommunikationsadresse
          : this.onSelect(this.person.kommunikationsadresse);
        this.kommunikationsmail = this.person.kommunikationsmail
          ? this.person.kommunikationsmail
          : {};
      },
    },

    uniqueArray() {
      return [...new Set(this.adressen)];
    },
  },
  created() {},
  methods: {
    setFirma(firma) {
      this.firma = firma;
      this.kommunikationsadresse = firma.adressen[0];
      this.adressen = [...new Set(firma.adressen)];
      this.kommunikationsmail = this.firma.mailadressen[0];
      this.emitChanges();
    },
    onSelect(kommunikationsadresse) {
      this.kommunikationsadresse = kommunikationsadresse;
      this.emitChanges();
    },
    emitChanges() {
      this.$emit("dataChanged", {
        firma: this.firma,
        mitpersonmail: this.mitPersonMail,
        kommunikationsadresse: this.kommunikationsadresse,
        kommunikationsmail: this.kommunikationsmail,
      });
    },
    onCheckboxChange() {
      if (this.mitPersonMail) {
        // Checkbox is enabled
        this.mitPersonMail = false;
        this.kommunikationsmail = this.firma.mailadressen[0];
      } else {
        // Checkbox is disabled
        this.mitPersonMail = true;
        this.kommunikationsmail = this.person.mailadressen.find(
          (entry) => entry.hauptadresse === true
        );
      }
      this.emitChanges();
    },
  },
};
</script>

<style></style>
