import TemplateBildungsgangliste from "@/pages/Dashboardlisten/TemplateBildungsgangliste";
import TemplateBildungsgang from "@/pages/Bildung/Template/TemplateBildungsgang";
import TemplateKlassenliste from "@/pages/Dashboardlisten/TemplateKlassenliste";
import TemplateKlasse from "@/pages/Bildung/Template/TemplateKlasse";
import TemplateFaecherliste from "@/pages/Dashboardlisten/TemplateFaecherliste";
import TemplateFach from "@/pages/Bildung/Template/TemplateFach";
import TemplateKursliste from "@/pages/Dashboardlisten/TemplateKursListe";
import TemplateKurs from "@/pages/Bildung/Template/TemplateKurs";
import TemplateKurzveranstaltungsliste from "@/pages/Dashboardlisten/TemplateKurzveranstaltungsliste.vue";
import TemplateKurzveranstaltung from "@/pages/Bildung/Template/TemplateKurzveranstaltung.vue";

export default [
  {
    path: "/Bildung/Template/Bildungsgangliste",
    name: "template-bildungsgang-liste",
    component: TemplateBildungsgangliste
  },
  {
    path: "/Bildung/Template/Bildungsgang/:lehrgangid?/:anzeige",
    name: "template-bildungsgang",
    component: TemplateBildungsgang,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        lehrgangid: route.params.lehrgangid,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  },
  {
    path: "/Bildung/Template/Klassenliste",
    name: "template-klassen-liste",
    component: TemplateKlassenliste
  },
  {
    path: "/Bildung/Template/Klasse/:klassenid/:anzeige",
    name: "template-klasse",
    component: TemplateKlasse,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        klassenid: route.params.klassenid,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  },
  {
    path: "/Bildung/Template/Faecherliste",
    name: "template-faecher-liste",
    component: TemplateFaecherliste
  },
  {
    path: "/Bildung/Template/Fach/:fachid/:anzeige",
    name: "template-fach",
    component: TemplateFach,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        fachid: route.params.fachid,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  },
  {
    path: "/Bildung/Template/Kursliste",
    name: "template-kurs-liste",
    component: TemplateKursliste
  },
  {
    path: "/Bildung/Template/Kurs/:kursid?/:anzeige",
    name: "template-kurs",
    component: TemplateKurs,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        kursid: route.params.kursid,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  },
  {
    path: "/Bildung/Template/Kurzveranstaltungsliste",
    name: "template-kurzveranstaltungs-liste",
    component: TemplateKurzveranstaltungsliste
  },
  {
    path: "/Bildung/Template/Kurzveranstaltung/:lehrgangid?/:anzeige",
    name: "template-kurzveranstaltung",
    component: TemplateKurzveranstaltung,
    props: route => {
      const a = parseInt(route.params.anzeige, 10);
      return {
        lehrgangid: route.params.lehrgangid,
        anzeige: isNaN(a) ? 0 : a
      };
    }
  }
];
